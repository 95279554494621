// calculates, if the row is longer than 4 columns. If so, removes
// the last article and does so until it fits into a 4-column grid
export const normalizeGridRow = row => {
  let currentRow = { ...row }
  const countLength = row =>
    row.articles.reduce((accumulator, currentValue) => {
      currentValue.size === 'large'
        ? (accumulator = accumulator + 2)
        : accumulator++
      return accumulator
    }, 0)

  let currentLength = countLength(currentRow)

  while (currentLength > 12) {
    currentRow.articles.pop()
    currentLength = countLength(currentRow)
  }

  return currentRow
}

export const reduceGridRow = rowOfArticles => {
  const articles = [...rowOfArticles]
  while (articles.length > 4) articles.pop()
  return articles
}
