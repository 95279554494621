import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import FakeTertiaryLink from './UIComponents/FakeTertiaryLink'

const SmallArticleCard = ({
  author,
  category,
  date,
  excerpt,
  thumbnail,
  title,
  url,
  isRelatedGridRow,
  isNew
}) => {
  return (
    <a href={`/${url}`} className="mb-auto" aria-label={title}>
      <article className="w-full mb-12 sm:mb-4 group overflow-hidden">
        <div className="w-full">
          <img
            src={thumbnail}
            alt={title}
            className="w-full object-cover object-fit self-start max-h-20 lg:max-h-30"
            loading="lazy"
          />
        </div>
        <div className="break-words w-full flex flex-wrap flex-col mt-4 sm:transform sm:transition-all sm:duration-150 sm:translate-y-5 sm:group-hover:translate-y-0 md:mt-3">
          
          <div className="flex justify-between">
            {category && (
              <span className="text-13 text-black-700.7 mb-1">
                {category}
              </span>
            )}
            {isNew && (
              <span className="text-13 text-white bg-black-900 rounded-lg mb-1 mr-1 p-1">
                Neu
              </span>
            )}
          </div>
          
          {isRelatedGridRow ? 
              <h4 className="w-full text-xl font-bold leading-tight mb-1 text-black-700 sm:w-10/12 md:text-2xl">
                {title}
              </h4>
              :
              <h3 className="w-full text-xl font-bold leading-tight mb-1 text-black-700 sm:w-10/12 md:text-2xl">
                {title}
              </h3>
            }
          <div className="flex w-full text-13 text-black-700.7 sm:w-10/12">
            {date && (
              <span className="mr-2">
                {format(new Date(date), 'dd.MM.yyyy')}
              </span>
            )}
            {author && <span>{author}</span>}
          </div>
          <hr className="h-px w-12 my-3 border-none bg-black-700.5" />
          <p className="w-full text-15 mb-2 text-black-700 sm:w-10/12">
            {excerpt}
          </p>
          <FakeTertiaryLink text="Weiterlesen" className="mr-auto" />
        </div>
      </article>
    </a>
  )
}

SmallArticleCard.propTypes = {
  author: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default SmallArticleCard
